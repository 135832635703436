import React, { useState, useEffect } from 'react';
import './AdminLogin.css';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import { httpPost } from '../../http';
const TextBox = (props) => {
    return(
        <input className='admin-login-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const AdminLogin = (props) => {

    const { history: { location: { state: reg } } } = props

    const [ estado, setEstado ] = useState({
        
    });

    const [ login, setLogin ] = useState({
        nit: '',
        password: '',
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        
    }, []);

    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLogin({
            ...login,
            error: false,
            errorMsg: ''
        });

        try{
            const adminUser = await httpPost('/ProveedoresWS/api/AdminUser/login', login);
            if(adminUser.data){
                sessionStorage.setItem('adminUser', JSON.stringify(adminUser.data));
                props.history.push('/admin');
                return 0;
            }
        } catch(error){
            console.log(error);
            if(error.response.status){
                if(error.response.status === 404 ){
                    setLogin({
                        ...login,
                        error: true,
                        errorMsg: 'Correo electrónico o contraseña incorrectos.'
                    });
                    return 0;
                }
            }
            setLogin({
                ...login,
                error: true,
                errorMsg: 'Ocurrio un error al ingresar.'
            });
        }
    }

    return(
        <div>
            <div className='admin-login-content'>
                <div className='admin-login-body'>
                    { login.error &&
                        <Alert variant='danger'>{ login.errorMsg }</Alert>
                    }
                    <div>
                        <h2 className='admin-login-h2'>Ingreso a la administración de la plataforma</h2>
                    </div>
                    <div>
                        <p className='admin-login-p'>Ingrese su Correo electrónico y su contraseña para acceder</p>
                    </div>
                    <form className='admin-login-form' onSubmit={ handleSubmit }>
                        <div className='admin-login-field'>
                            <div className='admin-login-field-name'>Correo electrónico:</div>
                            <TextBox type='text' name='email' value={login.email} onChange={handleChange} />
                        </div>
                        <div className='admin-login-field'>
                            <div className='admin-login-field-name'>Contraseña:</div>
                            <TextBox type='password' name='password' value={login.password} onChange={handleChange} />
                        </div>
                        <br />
                        <Button type='submit'>Ingresar</Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;