import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { getEmpresa } from '../../misc';
import './Certificados.css';

const Certificado = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='certificado-container'>
                <div className='certificado-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faAward} />
                </div>
                <div>
                    <div className='certificado-title'>{ props.children }</div>
                </div>
            </div>
        </div>
    );
}

const Certificados = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/certificados-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/certificados-empresa');
                return 0;
            }
        }

        const newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

    }, []);

    return(
        <div className='page-container'>
            <Title>Certificados</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { getEmpresa(props.history.location.state.idEmpresa).razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione el tipo de certificado</SubTitle>
                <div>
                    <Row className='justify-content-md-center co-row'>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/retencion', state: { type: 'RETEFUENTE', idEmpresa: props.history.location.state.idEmpresa } }}><Certificado>Retencion en la fuente</Certificado></Link>
                        </Col>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/retencion', state: { type: 'RETEICA', idEmpresa: props.history.location.state.idEmpresa } }}><Certificado>Retencion I.C.A.</Certificado></Link>
                        </Col>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/rete-iva', state: { idEmpresa: props.history.location.state.idEmpresa } }}><Certificado>Retencion I.V.A.</Certificado></Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Certificados;