import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ConfirmModal from '../../../components/modal/confirmModal/ConfirmModal';
import { httpGet, httpPut, httpPost, httpDelete } from '../../../http';
import './Proveedores.css';

const Proveedores = (props) => {

    const [ admin, setAdmin ] = useState({
        email: '',
        nombre: ''
    });

    const [ proveedores, setProveedores ] = useState({
        data: [],
        loading: true,
        error: {},
        showModal: false
    });

    const [ proveedor, setProveedor ] = useState({
        id: 0,
        nit: '',
        razonSocial: '',
        email: '',
        createAt: '',
        state: false
    });

    const [ showModal, setShowModal ] = useState(false); 

    useEffect(() => {
        const newAdmin = JSON.parse(sessionStorage.getItem('adminUser'));
        setAdmin(newAdmin);

        getProveedores();

    }, []);

    const getProveedores = () => {
        setProveedores({
            ...proveedores,
            loading: true
        });
        httpGet('/ProveedoresWS/api/proveedor').then(
            resp => {
                console.log(resp.data);
                setProveedores({
                    ...proveedores,
                    data: resp.data,
                    loading: false
                })
            }
        ).catch(
            error => {
                console.log(error);
                setProveedores({
                    ...proveedores,
                    loading: false
                });
            }
        );

    }

    const handleModalRemove = (e, proveedor) => {
        setProveedor(proveedor);
        setShowModal(true);
    }

    const handleRemove = () => {
        httpDelete('/ProveedoresWS/api/proveedor/' + proveedor.id).then(
            resp => {
                setShowModal(false);
                console.log(resp.data);
                getProveedores();
            }
        ).catch(
            error => {
                console.log(error);
                setShowModal(false);
            }
        );
    }

    const closeModal = () => {
        setShowModal(false);
    }

    return(
        <div className='page-container'>
            <Title>Administracion de proveedores</Title>
            <div className='page-body'>
            </div>
            <div>
                { proveedores.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>N.I.T.</th>
                            <th>RAZON SOCIAL</th>
                            <th>CORREO ELECTRÓNICO</th>
                            <th>NUMERO TEL.</th>
                            <th>FECHA DE REGISTRO</th>
                            <th>ELIMINAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            proveedores.data.map(
                                proveedor => {
                                    return(
                                        <tr key={proveedor.id}>
                                            <td>{ proveedor.nit }</td>
                                            <td>{ proveedor.razonSocial }</td>
                                            <td>{ proveedor.email }</td>
                                            <td>{ proveedor.tel }</td>
                                            <td>{ proveedor.createAt }</td>
                                            <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { handleModalRemove(e, proveedor) }} ><FontAwesomeIcon className='view-icon' icon={faTrashAlt} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
            <ConfirmModal title='Eliminar proveedor' show={ showModal } onAccept={ handleRemove } onClose={ closeModal }>¿Desea eliminar el proveedor { proveedor.razonSocial }?</ConfirmModal>
        </div>
    );
}

export default Proveedores;