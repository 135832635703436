import React, { useState, useEffect } from 'react';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { httpGet, httpPut } from '../../http';
import { Alert, Spinner } from 'react-bootstrap';
import './Signup.css';

const TextBox = (props) => {
    return(
        <input className='signup-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Field = (props) => {
    return(
        <div className='signup-field'>
            <div className='signup-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} /></div>
            <div className='signup-field-hint'>{ props.hint }</div>
        </div>
    );
}


const Signup = (props) => {

    const [ estado, setEstado ] = useState({
        
    });

    const [ form, setForm ] = useState({
        razonSocial: '',
        nit: '',
        email: '',
        email2: '',
        cel: '',
        password1: '',
        password2: '',
        error: false,
        errorMsg: '',
        loading: false
    });

    useEffect(() => {
        /*if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }*/

        setEstado(JSON.parse(sessionStorage.getItem('estado')));

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{

            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: true
            });
    
            let vNit = await valNit(form.nit);
            if (vNit.data.resp) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El N.I.T. ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }
    
            let vEmail = await valEmail(form.email);
            if (vEmail.data.resp) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El Correo electrónico ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }
    
            if (form.email !== form.email2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Los correos electrónicos no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            if (form.password1 !== form.password2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            const proveedor = {
                nit: form.nit,
                razonSocial: form.razonSocial,
                email: form.email,
                tel: form.cel,
                password: form.password1
            }
    
            const newProveedor = await httpPut('/ProveedoresWS/api/proveedor', proveedor);
            if(newProveedor.data){
                console.log('PROVEEDOR CREADO');
                props.history.push({ pathname: '/login', state: { reg: true } });
                return 0;
            }
    
            
            
            console.log('FORMULARIO VALIDADO');
            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: false
            });
        } catch(error){
            setForm({
                ...form,
                error: true,
                errorMsg: 'Ocurrio un error al registrarse.'
            });
        };

    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const valNit = async (nit) => {
        const val = await httpGet('/ProveedoresWS/api/proveedor/val/nit/' + nit);
        return val;
    }

    const valEmail = async (email) => {
        const val = await httpGet('/ProveedoresWS/api/proveedor/val/email/' + email);
        return val;
    }

    return(
        <div>
            <div className='signup-content'>
                <div className='signup-body'>
                    <div>
                        <h2 className='signup-h2'>Registro de proveedor</h2>
                    </div>
                    <div>
                        <p className='signup-p'>Por favor llene los campos con asterisco(*)</p>
                        <InfoBox>Digite los campos obligatorios(*) para crear un nuevo proveedor</InfoBox>
                        { form.error &&
                            <>
                                <br />
                                <Alert variant='danger'>{ form.errorMsg }</Alert>
                            </>
                        }
                    </div>
                    <form className='signup-form' onSubmit={handleSubmit} >
                        <Field type='text' label='Razón social(*)' hint='Razón social del proveedor' name='razonSocial' value={form.razonSocial} onChange={handleChange} />
                        <Field type='text' label='N.I.T.(*)' hint='NIT sin dijito de verificación' name='nit' value={form.nit} onChange={handleChange} />
                        <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email' value={form.email} onChange={handleChange} />
                        <Field type='email' label='Repita su Correo electrónico(*)' hint='Dijite de nuevo su correo electrónico' name='email2' value={form.email2} onChange={handleChange} />
                        <Field type='text' label='Numero celular(*)' hint='Dijite su numero celular' name='cel' value={form.cel} onChange={handleChange} />
                        <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={form.password1} onChange={handleChange} />
                        <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={form.password2} onChange={handleChange} />
                        { form.loading &&
                            <>
                                <br />
                                <Spinner animation="border" variant="primary" />
                            </>
                        }
                        { !form.loading &&
                            <>
                                <br />
                                <Button type='submit'>Registrarse</Button>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signup;