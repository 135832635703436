import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { formatNumber, getYear, getCurrentDate, getEmpresa } from '../../misc';
import './Retencion.css';
import Certificados from './Certificados';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Button from '../../components/button/Button';

const Fecha = (props) => {
    return(
        <div className='fecha-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

const Retencion = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        nombreAlterno: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/certificados-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/certificados-empresa');
                return 0;
            }
        }

        const newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

        const type = props.history.location.state.type;
        if(type !== 'RETEIVA'){
            const tipo = setType();            
            const type = props.history.location.state.type;

            setRetencion({
                ...retencion,
                empresa: getEmpresa(props.history.location.state.idEmpresa),
                tipo: tipo,
                reteIva: false,
            });
        } else {
            getReteIVA();
        }
        
    }, []);

    const getReteFuente = async () => {
        setRetencion({
            ...retencion,
            loading: true,
            tipo: {
                ...retencion.tipo,
                visible: true,
            }
        });

        console.log("URL:");
        console.log(retencion.tipo.url);

        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const tercero = await httpGet('/ProveedoresWS/api/proveedor/tercero?empresa=' + props.history.location.state.idEmpresa + '&tercero=' + proveedor.nit);
        const certificado = await httpGet(retencion.tipo.url  + '&fechaDesde=' + retencion.tipo.fechaDesde.replace(/-/g, '') + "&fechaHasta=" + retencion.tipo.fechaHasta.replace(/-/g, ''));

        console.log('CERTIFICADO');
        console.log(certificado);
        
        let totalBase = 0;
        let totalRetencion = 0;

        for( const reg of certificado.data){
            totalBase = totalBase + reg.base;
            totalRetencion = totalRetencion + reg.retencion;
        }

        let total = {
            base: totalBase,
            retencion: totalRetencion
        }

        if((certificado !== null) && (certificado !== undefined)){
            const type = props.history.location.state.type;
            setRetencion({
                ...retencion,
                proveedor: tercero.data,
                data: certificado.data,
                total: total,
                loading: false,                
                tipo: {
                    ...retencion.tipo,
                    periodoGravable: type !== 'RETEIVA' ? 'Periodo gravable ' + retencion.tipo.fechaDesde.substring(0, 4) : retencion.tipo.periodoGravable,
                    visible: true,
                }
            });
        }
        
    }

    const getReteIVA = async () => {
        
        const tipo = setType();
        const estado2 = JSON.parse(sessionStorage.getItem('estado'));
        
        setRetencion({
            ...retencion,
            empresa: estado2.empresa,
            tipo: tipo,
            reteIva: false,
            loading: true,
            tipo: {
                ...retencion.tipo,
                visible: true
            }            
        });

        console.log("URL:");
        console.log(retencion.tipo.url);

        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const tercero = await httpGet('/ProveedoresWS/api/proveedor/tercero?empresa=' + props.history.location.state.idEmpresa + '&tercero=' + proveedor.nit);
        const certificado = await httpGet(tipo.url);

        console.log('CERTIFICADO');
        console.log(certificado);
        
        let totalBase = 0;
        let totalRetencion = 0;

        for( const reg of certificado.data){
            totalBase = totalBase + reg.base;
            totalRetencion = totalRetencion + reg.retencion;
        }

        let total = {
            base: totalBase,
            retencion: totalRetencion
        }

        if((certificado !== null) && (certificado !== undefined)){
            const type = props.history.location.state.type;
            setRetencion({
                ...retencion,
                proveedor: tercero.data,
                data: certificado.data,
                total: total,
                loading: false,                
                tipo: {
                    ...retencion.tipo,
                    visible: true,
                }
            });
        }
        
    }

    const [retencion, setRetencion ] = useState(
        {
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            total: {
                base: 0,
                retencion: 0
            },
            tipo: {
                codigo: '',
                titulo: '',
                periodoGravable: '',
                ciudad: '',
                fechaDesde: '',
                fechaHasta: '',
                visible: false
            },
            loading: false,
            error: {}
        }
    );

    const setType = () => {
        const type = props.history.location.state.type;
        let tipo = {};
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const estado2 = JSON.parse(sessionStorage.getItem('estado'));

        switch(type){
            case 'RETEFUENTE':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención en la fuente',
                    periodoGravable: 'Año gravable: ' + getYear(1),
                    url: '/ProveedoresWS/api/Retencion/retefuente?empresa=' + props.history.location.state.idEmpresa + '&tercero=' + proveedor.nit,
                    ciudad: 'Cali',
                    visible: false
                }
                break;
            case 'RETEICA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención Industria, Comercio y Avisos (I.C.A.)',
                    periodoGravable: 'Año gravable: ' + getYear(1) ,
                    url: '/ProveedoresWS/api/Retencion/reteica?empresa=' + props.history.location.state.idEmpresa + '&tercero=' + proveedor.nit,
                    ciudad: 'Cali',
                    visible: false
                }
                break;
            case 'RETEIVA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención I.V.A.',
                    periodoGravable: 'Periodo gravable: ' + props.history.location.state.periodo.descripcion + " " + props.history.location.state.periodo.lapsoFinal.substring(0, 4),
                    url: '/ProveedoresWS/ReteIvaDetalle?emp=CA&nit=' + proveedor.nit + '&lapsoInicial=' + props.history.location.state.periodo.lapsoInicial + '&lapsoFinal=' + props.history.location.state.periodo.lapsoFinal,
                    ciudad: estado2.empresa.ciudad,
                    visible: true
                }
                break;
        }

        return tipo;
    }

    const handleChange = (e) => {
        setRetencion({
            ...retencion,
            tipo: {
                ...retencion.tipo,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleClick = () => {
        console.log("FECHA DESDE:")
        console.log(retencion.tipo.fechaDesde.replace(/-/g, ''));
        console.log("FECHA HASTA:")
        console.log(retencion.tipo.fechaHasta.replace(/-/g, ''));

        if((retencion.tipo.fechaDesde.length > 0) && (retencion.tipo.fechaHasta.length > 0)){
            getReteFuente();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    return(
        <div className='page-container'>
            <Title>{ retencion.tipo.titulo }</Title>
            
            <div className='page-body'>

                <br/>
                { retencion.tipo.codigo !== 'RETEIVA' &&
                <>
                    <Alert variant='warning'>Señor proveedor tenga en cuenta que la informacion del mes actual y el mes anterior puede variar.</Alert>
                    <div className='retencion-fechas-container'>
                        <Fecha label="Fecha inicial:" name='fechaDesde' value={retencion.tipo.fechaDesde} onChange={handleChange} /> 
                        <Fecha label="Fecha final:" name='fechaHasta' value={retencion.tipo.fechaHasta} onChange={handleChange} />
                        <Button onClick={handleClick}>Consultar</Button>
                    </div>
                </>
                }
                
                {retencion.tipo.visible &&
                <>
                {retencion.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <br/>
                <br/>
                <SubTitle>{ retencion.tipo.titulo } { getEmpresa(props.history.location.state.idEmpresa).razonSocial } </SubTitle>
                <div>
                    <div>{ getEmpresa(props.history.location.state.idEmpresa).razonSocial }</div>
                    <div>{ getEmpresa(props.history.location.state.idEmpresa).nit }</div>
                </div>
                <br />
                <div>
                    Cali
                </div>
                <br />
                <SubTitle>{ retencion.tipo.periodoGravable }</SubTitle>
                <div>
                    <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.descripcion }</span></div>
                    <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.codigo }-{ retencion.proveedor.digitoVerificacion }</span></div>
                    <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                    <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                    <br />
                    <br />
                    <div><span className='info-title'>Ciudad donde se practicó la retención: </span><span>Cali</span></div>
                    <div><span className='info-title'>Ciudad donde se consignó la retención: </span><span>Cali</span></div>
                </div>
                <br />
                <table className='info-table tabla-retencion'>
                    <thead>
                        <tr>
                            <th>CONCEPTO</th>
                            <th>TASA</th>
                            <th>BASE</th>
                            <th>RETENCION</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            retencion.data.map(
                                rete => {
                                    return(
                                        <tr>
                                            <td>{ rete.descripcionCuenta }</td>
                                            <td>{ rete.tasa }%</td>
                                            <td>${ formatNumber(rete.base) }</td>
                                            <td>${ formatNumber(rete.retencion) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td colSpan='2'>TOTAL</td>
                            <td>${ formatNumber(retencion.total.base) }</td>
                            <td>${ formatNumber(retencion.total.retencion) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <div>
                    <div className='retencion-footer-text'>Fecha de expedición { getCurrentDate() }</div>
                    < br/>
                    <div>
                        <span>Descargar </span>
                        <span className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/certificado-pdf', state: { idEmpresa: props.history.location.state.idEmpresa, retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></span>
                    </div>
                </div>
                </>
                }
                </>
                }
            </div>
            
        </div>
    );
}

export default Retencion;
