import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import './Home.css';

const PageTitle = (props) => {
    return(
        <div className='page-title'>
            <h2>{ props.children }</h2>
        </div>
    );
}

const Home = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {
        /*setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            proveedor: JSON.parse(sessionStorage.getItem('proveedor'))
        });*/

        let newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

    }, []);

    return(
        <div className='page-container'>
            <Title>Bienvenido al portal para proveedores</Title>
            <div className='page-body'>
                <div>
                    Navegue a través de las opciones de la izquierda.
                </div>
                <br />
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th colSpan='2'>Información del proveedor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div className='table-title'>N.I.T.:</div></td>
                            <td>{ proveedor.nit }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Razón social:</div></td>
                            <td>{ proveedor.razonSocial }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Celular:</div></td>
                            <td>{ proveedor.tel }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Correo eléctronico:</div></td>
                            <td>{ proveedor.email }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Home;