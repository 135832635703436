import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import './Egresos.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, getEmpresa } from '../../misc/index';


const Egresos = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/egresos-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/egresos-empresa');
                return 0;
            }
        }

        let newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

        getEgresos(newProveedor.nit);

    }, []);

    const getEgresos = (tercero) => {
        setEgresos({
            ...egresos,
            loading: true
        });
        httpGet('/ProveedoresWS/api/Egresos?empresa=' + props.history.location.state.idEmpresa + '&fechaDesde=20200101&fechaHasta=20301231&tercero=' + tercero).then(
            resp => {
                setEgresos({
                    ...egresos,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setEgresos({
                    ...egresos,
                    loading: false
                });
            }
        );
    }

    const [egresos, setEgresos ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    const generarEgresoDetallePdf = async (e, egreso2) => {
        e.preventDefault();

        console.log(egreso2);
        
        /*const encabezado = await httpGet('/ProveedoresWS/VerEgreso?empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWS/VerEgresoDetalle?empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);*/

        const encabezado = await httpGet('/ProveedoresWS/api/Egresos/encabezado?emp=' + props.history.location.state.idEmpresa + '&empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWS/api/Egresos/detalle?emp=' +  props.history.location.state.idEmpresa + '&empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);

        if((encabezado !== null) && (encabezado !== undefined)){
            let egreso = {
                proveedor: proveedor,
                encabezado: encabezado.data,
                detalles: detalles.data
            }

            console.log(egreso);

            props.history.push({ pathname: '/portal/egreso-pdf', state: { idEmpresa: props.history.location.state.idEmpresa, egreso: egreso } });
        }
        
    }

    return(
        <div className='page-container'>
            <Title>Comprobantes de egreso</Title>
            {egresos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>VALOR DEL DOCUMENTO</th>
                                <th>DETALLE</th>
                                <th>VER</th>
                                <th>DESCARGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                egresos.data.map(
                                    egreso => {
                                        return(
                                            <tr key={egreso.id}>
                                                <td>{ egreso.tipoDoc + '-' + egreso.numeroDoc }</td>
                                                <td>{ egreso.fechaDoc }</td>
                                                <td>${ formatNumber(egreso.valor) }</td>
                                                <td>{ egreso.detalle }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: "/portal/egreso-detalle", state: { idEmpresa: props.history.location.state.idEmpresa, egreso: egreso } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                <td><div className='pdf-icon-container' ><Link style={{ color: 'red' }} onClick={(e) => { generarEgresoDetallePdf(e, egreso) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default Egresos
