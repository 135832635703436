import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import {Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getEmpresa } from '../../misc';
import Title from '../title/Title';
import './SeleccionarEmpresa.css';

const Empresa = (props) => {
    return(
        <div className='empresa-container'>
            <div className='empresa-icon-container'>
                <FontAwesomeIcon className='empresa-view-icon' icon={faStore} />
            </div>
            <div>
                <div className='empresa-title'>{ props.children }</div>
            </div>
        </div>
    );
}

const SeleccionarEmpresa = (props) => {

    return(
        <div className='page-container'>
            <Title>Empresas</Title>
            <div className='page-body'>
                <div>
                    Selecciones una empresa
                </div>
                <br />
                <div>
                    <Row className='justify-content-md-center empresa-row'>
                        <Col className='empresa-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '' }} onClick={(e) => { props.onClick(e, 1)}}><Empresa>{ getEmpresa(1).razonSocial }</Empresa></Link>
                        </Col>
                        <Col className='empresa-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '' }} onClick={(e) => { props.onClick(e, 2)}}><Empresa>{ getEmpresa(2).razonSocial }</Empresa></Link>
                        </Col>
                        <Col className='empresa-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '' }} onClick={(e) => { props.onClick(e, 3)}}><Empresa>{ getEmpresa(3).razonSocial }</Empresa></Link>
                        </Col>
                        <Col className='empresa-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '' }} onClick={(e) => { props.onClick(e, 4)}}><Empresa>{ getEmpresa(4).razonSocial }</Empresa></Link>
                        </Col>
                        <Col className='empresa-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '' }} onClick={(e) => { props.onClick(e, 5)}}><Empresa>{ getEmpresa(5).razonSocial }</Empresa></Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default SeleccionarEmpresa;