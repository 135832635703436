import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, getEmpresa } from '../../misc/index';
 

const EgresoDetalle = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/egresos-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/egresos-empresa');
                return 0;
            }
        }

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }

        const newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);
        
        getEgresoDetalle(props.history.location.state.egreso);

    }, []);

    const getEgresoDetalle = async (egreso2) => {
        setEgreso({
            ...egreso,
            loading: true
        });
        
        const encabezado = await httpGet('/ProveedoresWS/api/Egresos/encabezado?emp=' + props.history.location.state.idEmpresa + '&empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWS/api/Egresos/detalle?emp=' +  props.history.location.state.idEmpresa + '&empresa=CA&co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        console.log(encabezado);

        if((encabezado !== null) && (encabezado !== undefined)){
            setEgreso({
                ...egreso,
                encabezado: encabezado.data,
                detalles: detalles.data,
                loading: false
            });
        }
        
    }
    const [egreso, setEgreso ] = useState(
        {
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: '',
                fechaVcto: '',
                valor: '',
                debito: '',
                credito: '',
                detalle: '',
            },
            detalles: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    const generarEgresoDetallePdf = async () => {

        let newEgreso = {
            proveedor: proveedor,
            encabezado: egreso.encabezado,
            detalles: egreso.detalles
        }

        console.log(newEgreso);

        props.history.push({ pathname: '/portal/egreso-pdf', state: { idEmpresa: props.history.location.state.idEmpresa, egreso: newEgreso } });
    }

    return(
        <div className='page-container'>
            <Title>Detalle de egreso</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { getEmpresa(props.history.location.state.idEmpresa).razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del egreso</SubTitle>
                {egreso.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>FECHA DE VENCIMIENTO</th>
                            <th>VALOR DEL DOCUMENTO</th>                                                        
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>DETALLE</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numeroDoc }</td>
                            <td>{ egreso.encabezado.fechaDoc }</td>
                            <td>{ egreso.encabezado.fechaVcto }</td>
                            <td>${ formatNumber(egreso.encabezado.valor) }</td>
                            <td>${ formatNumber(egreso.encabezado.debito) }</td>
                            <td>${ formatNumber(egreso.encabezado.credito) }</td>
                            <td>{ egreso.encabezado.detalle }</td>
                            <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>CUENTA</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.detalles.map(
                                detalle => {
                                    return(
                                        <tr key={detalle.id}>
                                            <td>{detalle.id}</td>
                                            <td>{ detalle.coDocumento }</td>
                                            <td>{ detalle.idCuenta }</td>
                                            <td>{ detalle.tipoDocumentoProveedor + '-' + detalle.numeroDocumentoProveedor }</td>
                                            <td>${ formatNumber(detalle.debitos) }</td>
                                            <td>${ formatNumber(detalle.creditos) }</td>
                                            <td>{ detalle.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container'><Link to={{ pathname: '/portal/documentos-detalle', state: { idEmpresa: props.history.location.state.idEmpresa, detalle: detalle } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                </>
                }
            </div>
        </div>
    );
}

export default EgresoDetalle
