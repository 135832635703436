import React from 'react';
import { Link } from 'react-router-dom';
import SeleccionarEmpresa from '../../components/seleccionarEmpresa/SeleccionarEmpresa';

const EgresosEmpresa = (props) => {

    const handleSeleccionarEmpresa = (e, id) => {
        e.preventDefault();
        props.history.push({ pathname: '/portal/egresos', state: { idEmpresa: id } });
    }

    return(
        <div>
            <SeleccionarEmpresa onClick={handleSeleccionarEmpresa} />
        </div>
    );
}

export default EgresosEmpresa;