import React, { useState, useEffect } from 'react';
import Welcome from '../welcome/Welcome';
import Signup from '../signup/Signup';
import Login from '../login/Login';
import AdminLogin from '../adminLogin/AdminLogin';
import PasswordRecovery from '../passwordRecovery/PasswordRecovery';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './WelcomeLayout.css';
import logo from '../../img/logo-panaderia-kuty.png';

const WelcomeLayout = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: false,
        error: {}
    });

    useEffect(() => {
        if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }

        if(sessionStorage.getItem('proveedor')){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <div>
            <div className='header'>
                <img className='welcome-logo' src={logo} />
            </div>
            <div className='content'>
                <Switch>
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/recovery-password" component={PasswordRecovery} />
                    <Route path="/admin-login" component={AdminLogin} />
                    <Route path="/" component={Welcome} />
                </Switch>
            </div>
            <div className='footer-container'>
                <div className='footer'>
                <div><Link style={{ color: 'white' }} to='/admin-login'>Accesso administrador</Link></div>
                <div>Grupo empresarial Kuty 2021</div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeLayout