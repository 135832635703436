import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber, getEmpresa } from '../../misc/index';
import './Notas.css';

const Notas = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/egresos-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/egresos-empresa');
                return 0;
            }
        }

        let newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

        getNotas(newProveedor.nit);

    }, []);

    const getNotas = async (tercero) => {
        setNotas({
            ...notas,
            loading: true
        });
        
        const np = await httpGet('/ProveedoresWS/api/nota/tercero?empresa=' + props.history.location.state.idEmpresa + '&fechaDesde=20200101&fechaHasta=20301231&tipoDocumento=NP&tercero=' + tercero);
        //const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo/' + props.history.location.state.co);

        if((np !== null) && (np !== undefined)){
            setNotas({
                ...notas,
                //co: co.data,
                data: np.data,
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, nota) => {
        e.preventDefault();

        const encabezado = await httpGet('/ProveedoresWS/api/nota?empresa=' + props.history.location.state.idEmpresa + '&co=' + nota.co + '&tipoDocumento=' + nota.tipoDocumento + '&numeroDocumento=' + nota.numeroDocumento);
        const detalles = await httpGet('/ProveedoresWS/api/nota/detalle?empresa=' + props.history.location.state.idEmpresa + '&co=' + nota.co + '&tipoDocumento=' + nota.tipoDocumento + '&numeroDocumento=' + nota.numeroDocumento);
        const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo?empresa=' + props.history.location.state.idEmpresa + '&codigo=' + nota.co);

        if((encabezado !== null) && (encabezado !== undefined)){
            let documento = {
                co: co.data,
                proveedor: proveedor,
                encabezado: encabezado.data[0],
                detalles: detalles.data
            }

            props.history.push({ pathname: '/portal/nota-pdf', state: {idEmpresa: props.history.location.state.idEmpresa, documento: documento} });
        }
    }

    const [notas, setNotas ] = useState(
        {
            co: {
                codigo: '',
                nombre: ''
            },
            data: [],
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Notas de proveedor</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { getEmpresa(props.history.location.state.idEmpresa).razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Notas de proveedor</SubTitle>
                { notas.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>FECHA</th>
                            <th>TOTAL</th>
                            <th>DETALLE</th>
                            <th>VER</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            notas.data.map(
                                documento => {
                                    return(
                                        <tr key={documento.key}>
                                            <td>{ documento.tipoDocumento + '-' + documento.numeroDocumento }</td>
                                            <td>{ documento.co }</td>
                                            <td>{ documento.documentoProveedor }</td>
                                            <td>{ documento.fechaDoc }</td>
                                            <td>${ formatNumber(documento.totalNeto) }</td>
                                            <td>{ documento.detalle }</td>
                                            <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-detalle', state: { idEmpresa: props.history.location.state.idEmpresa, documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { generarPdf(e, documento) }} ><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
        </div>
    );
}

export default Notas;
