import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import './AdminHome.css';

const AdminHome = (props) => {

    const [ admin, setAdmin ] = useState({
        email: '',
        nombre: ''
    });

    useEffect(() => {
        const newAdmin = JSON.parse(sessionStorage.getItem('adminUser'));
        setAdmin(newAdmin);
    }, []);

    return(
        <div className='page-container'>
            <Title>Administracion del portal para proveedores</Title>
            <div className='page-body'>
                <div>
                    Navegue a través de las opciones de la izquierda.
                </div>
            </div>
        </div>
    );
}

export default AdminHome;