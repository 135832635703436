import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { httpGet } from '../../http';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getYear } from '../../misc';
import './ReteICA.css';

const Ciudad = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='ciudad-container'>
                <div className='ciudad-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faAward} />
                </div>
                <div>
                    <div className='ciudad-title'>{ props.children }</div>
                </div>
            </div>
        </div>
    );
}

const ReteICA = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [ ciudadesReteIca, setCiudadesReteIca ] = useState({
        data: [],
        loading: false,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        getCiudades();

    }, []);

    const getCiudades = async () => {
        setCiudadesReteIca({
            ...ciudadesReteIca,
            loading: true
        });
        
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const ciudades = await httpGet('/ProveedoresWS/api/CiudadReteIca/tercero/' + proveedor.nit + '?anno=' + getYear(1));

        if((ciudades !== null) && (ciudades !== undefined)){
            setCiudadesReteIca({
                ...ciudadesReteIca,
                data: ciudades.data,
                loading: false
            });
        }
    }

    return(
        <div className='page-container'>
            <Title>Certificados</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial} </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione la ciudad</SubTitle>
                <div>
                    <Row className='justify-content-md-center co-row'>
                        {ciudadesReteIca.data.map((ciudad) => {
                            return(
                                <Col key={ciudad.id} className='co-col' xs={12} md={6} lg={6} xl={4} >
                                    <Link to={{ pathname: '/portal/retencion', state: { type: 'RETEICA', ciudad: ciudad } }}><Ciudad>{ciudad.ciudad}</Ciudad></Link>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default ReteICA;
