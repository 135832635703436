import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import Home from './Home';
import Egresos from '../egresos/Egresos';
import EgresosEmpresa from '../egresos/EgresosEmpresa';
import EgresoDetalle from '../egresos/EgresoDetalle';
import DocumentosDetalle from '../egresos/DocumentosDetalle';
import DocumentoDetalle from '../egresos/DocumentoDetalle';
import Notas from '../notas/Notas';
import NotasEmpresa from '../notas/NotasEmpresa';
import NotaDetalle from '../notas/NotaDetalle';
import Retencion from '../certificados/Retencion';
import ReteICA from '../certificados/ReteICA';
import ReteIVA from '../certificados/ReteIVA';
import ReteIVADetalle from '../certificados/ReteIVADetalle';
import EgresoPDF from '../egresos/EgresoPDF';
import NotaPDF from '../egresos/NotaPDF';
import CentrosOperacion from '../notas/CentrosOperacion';
import Certificados from '../certificados/Certificados';
import CertificadosEmpresa from '../certificados/CertificadosEmpresa';
import CertificadoPDF from '../certificados/CertificadoPDF';
import Profile from '../../pages/profile/Profile';
import { Link } from 'react-router-dom';
import './HomeLayout.css';
import logo from '../../img/logo-panaderia-kuty.png';

const MenuOption = (props) =>  {
    return(
        <div className='menu-option-wrapper'>
            <div className='menu-icon-container' onMouseOver={props.onMouseOver} onMouseLeave={ props.onMouseLeave }>
                <Link style={{ color: 'white' }} to={props.to}><FontAwesomeIcon className='menu-icon' icon={props.icon} /></Link>
            </div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
}

const HomeLayout = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {
        /*if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }*/

        if(!sessionStorage.getItem('proveedor')){
            props.history.push('/login');
            return 0;
        }

        let newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

        /*console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);*/

    }, []);

    const [ menu, setMenu ] = useState({
        menuClass: 'menu',
        menuOptionText: 'menu-option-text'
    });

    const handleMouseOver = () => {
        setMenu({
            ...menu,
            menuClass: 'menu-active',
            menuOptionText: 'menu-option-text-active'
        });
    }

    const handleMouseLeave = () => {
        setMenu({
            ...menu,
            menuClass: 'menu',
            menuOptionText: 'menu-option-text'
        });
    }

    const exit = () => {
        sessionStorage.removeItem('proveedor');
        props.history.push('/');
    }

    return(
        <div>
            <Navbar fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md" style={{ height: '40px', justifyContent: 'space-between' }}> 
                <Navbar.Brand className="brand-title" href="#home">Portal para proveedores - Grupo empresarial Kuty</Navbar.Brand>
                <Navbar.Text>Hola { proveedor.razonSocial } <a href="#" onClick={exit}>Salir</a></Navbar.Text>
            </Navbar>
        
        
            <div className='home-wrapper'>
                <div className={menu.menuClass}>
                    <div className='menu-logo-wrapper'>
                    </div>
                    <MenuOption className={menu.menuOptionText} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} icon={faFileInvoiceDollar} to='/portal/egresos-empresa'>Comprobantes de egreso</MenuOption>
                    <MenuOption className={menu.menuOptionText} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} icon={faFileAlt} to='/portal/notas-empresa'>Notas</MenuOption>
                    <MenuOption className={menu.menuOptionText} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} icon={faAward} to='/portal/certificados-empresa'>Certificados</MenuOption>
                    <MenuOption className={menu.menuOptionText} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} icon={faUserCircle} to='/portal/profile'>Perfil</MenuOption>
                </div>
            </div>

            <div className='home-logo-container'>
                <img className='home-logo' src={logo} />
            </div>
            
            <div className='home-main'>
                <Switch>
                    <Route path="/portal/egresos" render={() => (<Egresos history={props.history} />)} />
                    <Route path="/portal/egresos-empresa" render={() => (<EgresosEmpresa history={props.history} />)} />
                    <Route path="/portal/egreso-detalle" render={() => (<EgresoDetalle history={props.history} />)} />
                    <Route path="/portal/documentos-detalle" render={() => (<DocumentosDetalle history={props.history} />)} />
                    <Route path="/portal/documento-detalle" render={() => (<DocumentoDetalle history={props.history} />)} />
                    <Route path="/portal/notas" render={() => (<Notas history={props.history} />)} />
                    <Route path="/portal/notas-empresa" render={() => (<NotasEmpresa history={props.history} />)} />
                    <Route path="/portal/nota-detalle" render={() => (<NotaDetalle history={props.history} />)} />
                    <Route path="/portal/retencion" render={() => (<Retencion history={props.history} />)} />
                    <Route path="/portal/rete-ica" render={() => (<ReteICA history={props.history} />)} />
                    <Route path="/portal/rete-iva" render={() => (<ReteIVA history={props.history} />)} />
                    <Route path="/portal/rete-iva-detalle" render={() => (<ReteIVADetalle history={props.history} />)} />
                    <Route path="/portal/egreso-pdf" render={() => (<EgresoPDF history={props.history} />)} />
                    <Route path="/portal/nota-pdf" render={() => (<NotaPDF history={props.history} />)} />
                    <Route path="/portal/co-notas" render={() => (<CentrosOperacion history={props.history} />)} />
                    <Route path="/portal/certificados" render={() => (<Certificados history={props.history} />)} />
                    <Route path="/portal/certificados-empresa" render={() => (<CertificadosEmpresa history={props.history} />)} />
                    <Route path="/portal/certificado-pdf" render={() => (<CertificadoPDF history={props.history} />)} />
                    <Route path="/portal/Profile" render={() => (<Profile history={props.history} />)} />
                    <Route path="/portal" render={() => (<Home history={props.history} />)} />
                </Switch>
            </div>

        </div>
    );
}

export default HomeLayout