import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import './DocumentoDetalle.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, getEmpresa } from '../../misc/index';

const DocumentoDetalle = (props) => {

    const [ proveedor, setProveedor ] = useState({
        nit: '',
        razonSocial: '',
        tel: '',
        email: ''
    });

    useEffect(() => {

        if(!props.history.location.state){
            props.history.push('/portal/egresos-empresa');
            return 0;
        } else {
            if(!props.history.location.state.idEmpresa){
                props.history.push('/portal/egresos-empresa');
                return 0;
            }
        }

        const newProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setProveedor(newProveedor);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(props.history.location.state.documento);

    }, []);

    const getDocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
        
        const encabezado = await httpGet('/ProveedoresWS/api/nota?empresa=' + props.history.location.state.idEmpresa + '&co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const detalles = await httpGet('/ProveedoresWS/api/nota/detalle?empresa=' + props.history.location.state.idEmpresa + '&co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo?empresa=' + props.history.location.state.idEmpresa + '&codigo=' + doc.co);
        console.log(encabezado);
        console.log(detalles);
        console.log(co);

        if((encabezado !== null) && (encabezado !== undefined)){
            setDocumento({
                ...documento,
                co: co.data,
                encabezado: encabezado.data[0],
                detalles: detalles.data,
                loading: false
            });
        }
        
    }

    const [documento, setDocumento ] = useState(
        {
            co: {
                codigo: '',
                nombre: ''
            },
            encabezado: {
                co: '',
                tipoDocumento: '',
                numeroDocumento: '',
                fechaDoc: '',
                detalle: '',
                terceroDescripcion: '',
                documentoProveedor: '',
                totalBruto: '',
                descuentos: '',
                impuestos: '',
                totalNeto:''
            },
            detalles: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    return(
        <div className='page-container'>
            <Title>Documento de proveedor - Detalle</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { getEmpresa(props.history.location.state.idEmpresa).razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ proveedor.razonSocial }</span></div>
                    <div><span className='info-title'>Centro de operación </span><span>{ documento.co.codigo } - { documento.co.nombre }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del documento</SubTitle>
                { documento.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                    <>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>DETALLE</th>
                                <th>PROVEEDOR</th>            
                                <th>DOC. CRUCE</th>
                                <th>VALOR BRUTO</th>
                                <th>DESCUENTOS</th>
                                <th>IMPUESTOS</th>
                                <th>TOTAL</th>
                                <th>DESCARGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ documento.encabezado.co + " - " + documento.encabezado.tipoDocumento + '-' + documento.encabezado.numeroDocumento }</td>
                                <td>{ documento.encabezado.fechaDoc }</td>
                                <td>{ documento.encabezado.detalle }</td>
                                <td>{ documento.encabezado.terceroDescripcion }</td>
                                <td>{ documento.encabezado.documentoProveedor }</td>
                                <td>${ formatNumber(documento.encabezado.totalBruto) }</td>
                                <td>${ formatNumber(documento.encabezado.descuentos) }</td>
                                <td>${ formatNumber(documento.encabezado.impuestos) }</td>
                                <td>${ formatNumber(documento.encabezado.totalNeto) }</td>
                                <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/nota-pdf', state: { idEmpresa: props.history.location.state.idEmpresa, documento: { proveedor: proveedor, co: documento.co, encabezado: documento.encabezado, detalles: documento.detalles } } } } ><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>DESCRIPCION</th>
                                <th>CANTIDAD</th>
                                <th>UNIDAD DE MEDIDA</th>
                                <th>VALOR BRUTO</th>
                                <th>DESCUENTOS</th>
                                <th>IMPUESTOS</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                documento.detalles.map(
                                    detalle => {
                                        return(
                                            <tr key={detalle.id}>
                                                <td>{ detalle.idItem }</td>
                                                <td>{ detalle.descripcionItem }</td>
                                                <td>{ detalle.cantidad }</td>
                                                <td>{ detalle.unidadMedida }</td>
                                                <td>${ formatNumber(detalle.totalBruto) }</td>
                                                <td>${ formatNumber(detalle.descuentos) }</td>
                                                <td>${ formatNumber(detalle.impuestos) }</td>
                                                <td>${ formatNumber(detalle.totalNeto) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                    </>
                }
            </div>
        </div>
    );
}

export default DocumentoDetalle
