import logo from './logo.svg';
import './App.css';
import WelcomeLayout from './pages/welcome/WelcomeLayout';
import HomeLayout from './pages/home/HomeLayout'
import AdminLayout from './pages/admin/adminLayout/AdminLayout';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

function App() {
  return (
    <BrowserRouter basename={""}>
        <Switch>
            <Route path="/admin" component={AdminLayout} />
            <Route path="/portal" component={HomeLayout} />
            <Route path="/" component={WelcomeLayout} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;
