import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { httpGet } from '../../http';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CentrosOperacion.css';

const Co = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='co-container'>
                <div className='co-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faStore} />
                </div>
                <div>
                    <span className='co-title'>CODIGO: </span><span>{ props.co.codigo }</span>
                </div>
                <div>
                    <span className='co-title'>NOMBRE: </span><span>{ props.co.nombre }</span>
                </div>
            </div>
        </div>
    );
}

const CentroOperacion = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        getCos();

    }, []);

    const getCos = async () => {
        setCo({
            ...co,
            loading: true
        });
        
        const cos = await httpGet('/ProveedoresWS/api/CentroOperacion/visible');

        if((cos !== null) && (cos !== undefined)){
            setCo({
                ...co,
                data: cos.data,
                loading: false
            });
        }
        
    }

    const [co, setCo ] = useState(
        {
            data: [],
            loading: false,
            error: {}
        }
    );
    
    let x = 0;
    let contCo = 0;
    return(
        <div className='page-container'>
            <Title>Notas de proveedor</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial} </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione el centro de operacion</SubTitle>
                { co.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <div>
                    <Row className='justify-content-md-center co-row'>
                    {
                        co.data.map(
                            centro => {
                                return(
                                    <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                                        <Link to={{ pathname: '/portal/notas', state: { co: centro.codigo } }}><Co co={centro} /></Link>
                                    </Col>
                                );
                            }
                        )
                    }
                    </Row>
                </div>
                }
            </div>
        </div>
    );
}

export default CentroOperacion;
